.o-list {
  @include parent-behaviour();
  /*
  > :first-child {
    border-top: 1px solid $border-color;
  }
  */
}

.o-list-item {
  @include parent-behaviour();
}

.o-list-header {
  @include section--padding--less();
  border-bottom: 1px solid $border-color;
}

.o-list-item__head {
	@include section--padding--less();
  border-bottom: 1px solid $border-color;
  z-index: $z-sticky--lower;
  background-color: $white;
}

.o-list-item__content {
	@include section--padding();
  margin-bottom: $baseline;
}

.o-list-item .o-list-item__content {
  display:none;
  opacity: 0;
}

.o-list-item.is-active {
  border-bottom: 1px solid $black;

  .o-list-item__head {
    position: sticky;
    border-bottom: 1px solid $black;
    color: $black !important;
  }

  .o-list-item__content {
    display:block;
    opacity: 1;
  }

}

.o-list-item__column--float-right {
  position: absolute;
  right: 0;
  width: 2.75em;
}

.o-list-small-list-item {
  vertical-align: top;
  //padding-left: $padding/2;
  padding-right: $padding/2;
}
