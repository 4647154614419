// Typography
// Typographical base selectors. Define base styles on single element selectors here.

html,body {
  @include sans();
  color: $black;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tnum {
  -moz-font-feature-settings: "tnum" 1;
  -webkit-font-feature-settings: "tnum" 1;
	font-feature-settings: "tnum" 1;
}

a,
p a,
.type--hover {
  color: $black;
  text-decoration: none;
  @include transition(color, $fast, $ease);
  &:hover,
  &.is-active,
  &.is-current {
    color: $orange;
    text-decoration: none;
  }
}

p a,
.type--link{
  color: $textgray !important;
  cursor: pointer;
  &:hover,
  &.is-active,
  &.is-current {
    color: $orange !important;
  }
}

/*
nav .is-active a {
  color: $textgray;
}
*/

/*
.is-active,
.is-active a {
  color: $textgray;
}
*/

.type--color {
  color: $orange;
}

p {
  margin-bottom: $baseline/2;
  max-width: $max_text_width;
  &:last-child {
    margin-bottom: 0;
  }
}

@include mq($br-med-small) {
  .o-text p {
    margin-bottom: $baseline;
    max-width: $max_text_width;
    @include mq($br-med-small) {
      margin-bottom: $baseline--small;
    }
    &:last-child {
      margin-bottom: $baseline;
      max-width: $max_text_width;
      @include mq($br-med-small) {
        margin-bottom: $baseline--small;
      }
    }
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
  margin: 0;
  font-size: inherit;
}

sup {
  top: -0.75em;
  font-size: 50%;
}

b, strong {
  font-weight: normal;
}

em {
  font-style: normal;
}

.type--large {
  @include sans--large();
}

.type--normal {
  @include sans();
}

.type--small {
  @include sans--small();
}

.type--uppercase {
  text-transform: uppercase;
}

.type--tag:after {
  content: ", ";
}
.type--tag:last-child:after {
  content: "";
}
