.c-logo-container {
  position: relative;
  margin: $padding;
  @include mq($br-med-small) {
    margin: $padding--small;
  }
  /*
  @include mq($br-xlarge) {
    margin: $padding--br-xlarge;
  }
  @include mq($br-large) {
    margin: $padding--br-large;
  }
  @include mq($br-med-large) {
    margin: $padding--br-med-large;
  }
  @include mq($br-medium) {
    margin: $padding--br-medium;
  }
  @include mq($br-med-small) {
    margin: $padding--br-med-small;
  }
  @include mq($br-small) {
    margin: $padding--br-small;
  }
  @include mq($br-small) {
    margin-bottom: $baseline*1.5;
  }
  */
}

.c-logo-container--main {
  margin:0;
  padding: $padding;
  position: fixed;
  width:100%;
  //top:0;
  z-index: 1;
  @include mq($br-med-small) {
    padding: $padding--small;
  }
  /*
  @include mq($br-xlarge) {
    padding: $padding--br-xlarge;
  }
  @include mq($br-large) {
    padding: $padding--br-large;
  }
  @include mq($br-med-large) {
    padding: $padding--br-med-large;
  }
  @include mq($br-medium) {
    padding: $padding--br-medium;
  }
  @include mq($br-med-small) {
    padding: $padding--br-med-small;
  }
  @include mq($br-small) {
    padding: $padding--br-small;
  }
  */

}

.c-logo {
  position: relative;
  vertical-align: top;
  line-height: 0;
  font-size: 0;
  pointer-events:none;
  transition: none;
}
