//General

html {
	-webkit-box-sizing: border-box;
	   -moz-box-sizing: border-box;
			box-sizing: border-box;
}

html, body {
	//width:100%;
	//height:100%;
	//-webkit-overflow-scrolling: touch;
	//overflow-scrolling: touch;
	//overflow-x: hidden;
	//background-color: $orange;
	background-color: $white;
}

*, *::before, *::after {
	box-sizing: inherit;
}

::selection {
	background-color: transparent;
	color:$orange;
}

::-moz-selection {
	background-color: transparent;
	color:$orange;
}

.preload * {
	transition: none !important;
}

body.no-scroll {
	overflow: hidden;
}

.barba-container {
	position: relative;
}

.wrapper {
	position: relative;
  background-color: $white;
	z-index: 2;
}
