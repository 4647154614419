//Overlay
.o-overlay {

	@include overlay(100%, 100%, $z-overlay, $orange);
	opacity: 0;
	@include transition(opacity, $speed, $ease);

	&.is-animated,
	&.is-visible {
		pointer-events: auto;
		opacity: 1;
		@include transition(opacity, $speed, $ease);
	}

}

.o-overlay--page {
	display:none;

	&.is-activated {
		display:block;
	}
}

.o-overlay__content {
	width:100%;
	height:100%;
	overflow: scroll;
}
