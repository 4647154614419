
.c-intro {
  @include parent-behaviour();
  overflow: hidden;
  width:100%;
  height:100vh;
  z-index: 1;
  pointer-events: none;
  background-color: $orange;
  @include transition(height, $slow, $ease);
}

body.is-loaded {
  .c-intro {
    height: 0;
  }
}

.c-intro .c-logo {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}
