//Container
//Acts like a wrapper/inner div

.o-container {
	@include container();
	@include section--padding--less();
}

.o-container--page {
	@include container();
	padding-top: $padding/5;
  padding-bottom: $padding/2;
}
