// Layout

// Layout variables

$max_container_width: 100%;
$max_text_width: 75em;

$baseline: 1.2em;
$baseline--small: 1.25em;
$padding: 1.2em;
$padding--small: 1.25em;

//$padding--br-xlarge: 28px; //7*4
//$padding--br-large: 24px; //6*4
//$padding--br-med-large: 20px; //5*4
//$padding--br-medium: 18px; //4.5*4
//$padding--br-med-small: 18px; //4*4
//$padding--br-small: 16px; //3.5*4

// Breakpoints
$br-xlarge:           1800px;
$br-large:            1440px;
$br-med-large:        1280px;
$br-medium:           1024px;
$br-med-small:        768px;
$br-small:            520px;

// Grid
@mixin container() {
  @include parent-behaviour;
  width: 100%;
  max-width: $max_container_width;
  margin-left: auto;
  margin-right: auto;
}

@mixin section() {
  @include parent-behaviour;
}

@mixin section--padding() {
  @include parent-behaviour;
  padding-top: $padding/2;
  padding-bottom: $padding/2;
  @include mq($br-med-small) {
    padding-top: $padding--small/2;
    padding-bottom: $padding--small/2;
  }

/*
  @include mq($br-xlarge) {
    padding-top: $padding--br-xlarge/2;
    padding-bottom: $padding--br-xlarge/2;
  }
  @include mq($br-large) {
    padding-top: $padding--br-large/2;
    padding-bottom: $padding--br-large/2;
  }
  @include mq($br-med-large) {
    padding-top: $padding--br-med-large/2;
    padding-bottom: $padding--br-med-large/2;
  }
  @include mq($br-medium) {
    padding-top: $padding--br-medium/2;
    padding-bottom: $padding--br-medium/2;
  }
  @include mq($br-med-small) {
    padding-top: $padding--br-med-small/2;
    padding-bottom: $padding--br-med-small/2;
  }
  @include mq($br-small) {
    padding-top: $padding--br-small/2;
    padding-bottom: $padding--br-small/2;
  }
*/

}

@mixin section--padding--less() {
  @include parent-behaviour;
  padding-top: $padding/4;
  padding-bottom: $padding/4;
  @include mq($br-med-small) {
    padding-top: $padding--small/4;
    padding-bottom: $padding--small/4;
  }

  /*
  @include mq($br-xlarge) {
    padding-top: $padding--br-xlarge/4;
    padding-bottom: $padding--br-xlarge/4;
  }
  @include mq($br-large) {
    padding-top: $padding--br-large/4;
    padding-bottom: $padding--br-large/4;
  }
  @include mq($br-med-large) {
    padding-top: $padding--br-med-large/4;
    padding-bottom: $padding--br-med-large/4;
  }
  @include mq($br-medium) {
    padding-top: $padding--br-medium/4;
    padding-bottom: $padding--br-medium/4;
  }
  @include mq($br-med-small) {
    padding-top: $padding--br-med-small/4;
    padding-bottom: $padding--br-med-small/4;
  }
  @include mq($br-small) {
    padding-top: $padding--br-small/4;
    padding-bottom: $padding--br-small/4;
  }
  */
}

@mixin row($inside: false) {
  @if($inside == true) {
    margin-left: -$padding/2;
    margin-right: -$padding/2;
    @include mq($br-med-small) {
      margin-left: -$padding--small/2;
      margin-right: -$padding--small/2;
    }
    /*
    @include mq($br-xlarge) {
      padding-left: -$padding--br-xlarge/2;
      padding-right: -$padding--br-xlarge/2;
    }
    @include mq($br-large) {
      padding-left: -$padding--br-large/2;
      padding-right: -$padding--br-large/2;
    }
    @include mq($br-med-large) {
      padding-left: -$padding--br-med-large/2;
      padding-right: -$padding--br-med-large/2;
    }
    @include mq($br-medium) {
      padding-left: -$padding--br-medium/2;
      padding-right: -$padding--br-medium/2;
    }
    @include mq($br-med-small) {
      padding-left: -$padding--br-med-small/2;
      padding-right: -$padding--br-med-small/2;
    }
    @include mq($br-small) {
      padding-left: -$padding--br-small/2;
      padding-right: -$padding--br-small/2;
    }
    */
  }
  @else {
    @include container();
    padding-left: $padding/2;
    padding-right: $padding/2;
    @include mq($br-med-small) {
      padding-left: $padding--small/2;
      padding-right: $padding--small/2;
    }
    /*
    @include mq($br-xlarge) {
      padding-left: $padding--br-xlarge/2;
      padding-right: $padding--br-xlarge/2;
    }
    @include mq($br-large) {
      padding-left: $padding--br-large/2;
      padding-right: $padding--br-large/2;
    }
    @include mq($br-med-large) {
      padding-left: $padding--br-med-large/2;
      padding-right: $padding--br-med-large/2;
    }
    @include mq($br-medium) {
      padding-left: $padding--br-medium/2;
      padding-right: $padding--br-medium/2;
    }
    @include mq($br-med-small) {
      padding-left: $padding--br-med-small/2;
      padding-right: $padding--br-med-small/2;
    }
    @include mq($br-small) {
      padding-left: $padding--br-small/2;
      padding-right: $padding--br-small/2;
    }
    */
  }
  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin row-no-padding() {
  @include container();
  padding-left: 0;
  padding-right: 0;

  &:before, &:after {
    content: '';
    display: table;
  }
  &:after {
    clear: both;
  }
}

@mixin col($width: 1, $paddingBool: true, $centered: false) {
  @if($centered == true) {
    margin-left: auto;
    margin-right: auto;
    display:block;
  }
  @else {
    float: left;
  }
  width: percentage($width);
  @if($paddingBool == true) {
    padding-left: $padding/2;
    padding-right: $padding/2;
    @include mq($br-med-small) {
      padding-left: $padding--small/2;
      padding-right: $padding--small/2;
    }
  }
}

@mixin push($width: 0) {
  //position: relative;
  margin-left: percentage($width);
}
@mixin pull($width: 0) {
  //position: relative;
  margin-right: percentage($width);
}
