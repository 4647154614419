.o-block-item {
  @include section--padding--less();
  @include parent-behaviour();
  border-top: 1px solid $border-color;
  &:first-of-type {
		border-top: 0;
	}
}

.o-block-item__content {
  margin-bottom: $baseline;
}

//Collapsable/Expandable blocks

.o-block-item--collapsable .o-block-item__content {
  display:none;
  opacity: 0;
}

.o-block-item--collapsable.is-active .o-block-item__content {
  display:block;
  opacity: 1;
}
