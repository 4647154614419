//Utility classes for visibility states

.u-hidden {
  display: none !important;
}

.u-visible {
  display: block !important;
}

.u-visible-inline {
  display: inline !important;
}

.u-visible-inline-block {
  display: inline-block !important;
}

.u-visible-flex {
  display: flex !important;
}

.u-visible-inline-flex {
  display: inline-flex !important;
}

.u-visible-min-br-small {
	display: none !important;
	@include mq-min-w($br-small) {
		display: block !important;
	}
}

.u-visible-min-br-medium {
	display: none !important;
	@include mq-min-w($br-medium) {
		display: block !important;
	}
}

.u-visible-min-br-med-small {
	display: none !important;
	@include mq-min-w($br-med-small) {
		display: block !important;
	}
}

.u-hidden-max-br-med-small {
	display: block !important;
	@include mq($br-med-small) {
		display: none !important;
	}
}


.u-visible-min-br-large {
	display: none !important;
	@include mq-min-w($br-large) {
		display: block !important;
	}
}

.u-visible-max-br-small {
	display: none !important;
	@include mq($br-small) {
		display: block !important;
	}
}

.u-visible-max-br-medium {
	display: none !important;
	@include mq($br-medium) {
		display: block !important;
	}
}

.u-visible-max-br-med-small {
	display: none !important;
	@include mq($br-med-small) {
		display: block !important;
	}
}

.u-visible-max-br-large {
	display: none !important;
	@include mq($br-large) {
		display: block !important;
	}
}


.u-has-child-hoverstate {
  .u-visible-child-on-hover {
    display: none;
  }
  .u-hidden-child-on-hover {
    display: block;
  }
  &:hover {
    .u-visible-child-on-hover {
      display: block;
    }
    .u-hidden-child-on-hover {
      display: none;
    }
  }
}
