.c-main {
  @include parent-behaviour();
  //min-height: 100vh;
  padding-bottom: $padding;
  margin-top: -1px;
  z-index: 2;
}

body.is-loaded {
  .c-wrap {
    @include transform(translateY(0));
  }
}
