.o-title--h1 {
    margin-bottom: $baseline;
    @include mq($br-med-small) {
      margin-bottom: $baseline--small;
    }
}

.o-title--h2 {
    margin-bottom: $baseline;
    @include mq($br-med-small) {
      margin-bottom: $baseline--small;
    }
}
