//Container
//Acts like a wrapper/inner div

.o-section {
	@include section();
	margin-bottom: $baseline * 1.5;
}

.o-section--no-padding {
	@include section();
}
/*
.o-section-content {
	@include section();
	border-top: 1px solid $border-color;
	margin-bottom: $baseline * 1.5;
	&:first-of-type {
		border-top: 0;
	}
}
*/

.o-section--padding {
	@include section--padding();
}

.o-section--padding--less {
	@include section--padding--less();
}
