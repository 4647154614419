.u-inverted {
  background-color: $black !important;
  color: $white !important;
  a, p, ul, li, span, button {
    color: $white !important;
  }
  a:hover {
    color: $white !important;
  }
}
