// Forms
// Forms base selectors

fieldset {
	border: none;
	padding: 0;
	margin: 0;
}

input[type="checkbox"],
input[type="radio"] {
	margin-right: 5px;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type=number] {
    -moz-appearance: textfield;
}

form {
  @include parent-behaviour;
}

input,
textarea,
button,
select {
	
  //@include user-select(text);
  font-family: inherit;
  color: inherit;
  text-transform: inherit;
  font-weight: inherit;
  line-height: inherit;

  padding: 0;
  margin: 0;
  outline: none;
  cursor: pointer;
  border:0;
  border-radius: 0;
  background-color: transparent;

  -webkit-appearance: none;
  -moz-appearance: none;

  vertical-align: middle;
  height: auto;
  display:inline-block;

}

::-webkit-input-placeholder { opacity: 0.5; }
::-moz-placeholder      	  { opacity: 0.5; }
:-ms-input-placeholder    	{ opacity: 0.5; }
input:-moz-placeholder    	{ opacity: 0.5; }

form {

	label {
		display:none;
	}
}
