.c-header {
  position: sticky;
  top: -1px;
  width:100%;
  z-index: $z-sticky;
  background-color: $white;
  //padding-top: $padding/2;
}

.c-header-content {
  @include section--padding--less();
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
}
