// Color
$background: rgb(249, 248, 244);
$black: rgb(0, 0, 0);
$white: rgb(249, 248, 244);
$orange: rgb(255,61,0);

//$textgray: rgba(128, 128, 128);
//$border-color: rgb(128, 128, 128);
$textgray: rgba(0, 0, 0, 0.48);
$border-color: rgba(0, 0, 0, 1);
