// Flickity

/*! Flickity v2.0.10
http://flickity.metafizzy.co
---------------------------------------------- */

.flickity-enabled {
  position: relative;
}

.flickity-enabled:focus { outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%;
}

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%;
}

/* draggable */

.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
          tap-highlight-color: transparent;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab;
}

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}

/* ---- previous/next buttons ---- */
/*
.flickity-prev-next-button {
  position: absolute;
  bottom: $padding/2;
  width: 44px;
  height: 44px;
  border: none;
  cursor: pointer;
  transform: translateY(-50%);
  transform: none;
}

.flickity-prev-next-button {
  @include transition(opacity, $fast, $ease);
  opacity: 0;
}

.c-slider:hover {
  .flickity-prev-next-button {
    opacity: 1;
  }
}

.flickity-prev-next-button.previous {
  left: auto;
  right: $padding*2;
}

.flickity-prev-next-button.next {
  right: $padding;
}


.flickity-prev-next-button:disabled {
  opacity: 0.3;
  cursor: auto;
}

.flickity-prev-next-button svg {
  position: absolute;
  left: 16px;
  top: 16px;
  width: 16px;
  height: 16px;
}

.flickity-prev-next-button .arrow {
  fill: $white;
}
*/
//Slider
//Slider component

.c-slider {
  width: 100%;
  height: 100%;
  background-color: $orange;
  opacity: 0;
  @include transition(opacity, $normal, $ease);
}

.c-slider.is-hidden {
  display: none;
}

.c-slider.flickity-enabled {
  opacity: 1;
}

.c-slider__cell {
  width: 100%;
  height: 100%;
}

.c-slider--bgimg .c-slider__cell{
  height: 0;
  padding-bottom: 66.66%;
  background-color: $orange;
  background-size: cover;
}

//Slider button
.c-slider__btn {
  z-index: 2;
  position: absolute;
  width: 3em;
  height: 3em;
  top: 50%;
  margin-top: -1.5em;
  border: none;
  cursor: pointer;
  opacity: 0;
  @include transition(all, $fast, $ease);
  background-size: 1em;
  background-repeat: no-repeat;
}

.c-slider__btn--prev {
  background-image: url('../img/arrow--left--white.svg');
  background-position: center center;
  left: 0;
  &:hover {
    @include transform(translateX(-10%));
  }
}

.c-slider__btn--next {
  background-image: url('../img/arrow--right--white.svg');
  background-position: center center;
  right: 0;
  &:hover {
    @include transform(translateX(10%));
  }
}

.c-slider:hover {
  .c-slider__btn {
    opacity: 1;
  }
}

.c-slider__btn:disabled {
  opacity: 0.3;
  cursor: auto;
}
