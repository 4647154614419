// Media
// Base selectors for images, video, audio...

img, video {
    max-width: 100%;
    width: 100%;
    vertical-align: top;
}

img.lazyload,
img.lazyloading {
	opacity: 0;
}

img.lazyloaded {
	opacity: 1;
	@include transition(opacity, $speed, $ease);
}

figure.embed--video {
  position: relative;
  padding-top: 56.25% !important;
  height: 0;
}

figure.embed--video.embed--Vimeo {
  .embed__thumb img{
    display: none;
  }
}

figure.video {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
}

figure.video iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

.o-image-container {
  padding-top: $padding/2;
  padding-bottom: $padding/2;
  @include mq($br-med-small) {
    padding-top: $padding--small/2;
    padding-bottom: $padding--small/2;
  }
}

.o-caption {
  position: absolute;
  padding-top: $padding/5;
  @include mq($br-med-small) {
    padding-top: $padding--small/5;
  }
  /*
  @include mq($br-xlarge) {
    padding-top: $padding--br-xlarge/5;
  }
  @include mq($br-large) {
    padding-top: $padding--br-large/6;
  }
  @include mq($br-med-large) {
    padding-top: $padding--br-med-large/6;
  }
  @include mq($br-medium) {
    padding-top: $padding--br-medium/7;
  }
  @include mq($br-med-small) {
    padding-top: $padding--br-med-small/8;
  }
  @include mq($br-small) {
    padding-top: 1px;
  }
  */
}
