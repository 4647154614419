// Font settings
$sans: 'NHaasGroteskTXStd-55Rg', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansLight: 'NHaasGroteskTXStd-55Rg', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansItalic: 'NHaasGroteskTXStd-55Rg', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$sansLightItalic: 'NHaasGroteskTXStd-55Rg', 'Helvetica Neue', Helvetica, Arial, "Lucida Grande", sans-serif;
$normal: 400;


$br-xlarge:           1800px;
$br-large:            1440px;
$br-med-large:        1280px;
$br-medium:           1024px;
$br-med-small:        768px;
$br-small:            520px;

// Typography
@mixin sans() {
	font-family: $sans;
	font-size: 1.4vw; //20px
	line-height: $baseline;

	@include mq($br-xlarge) {
		//till 1800px
		//font-size: 22px;
		font-size: 1.4vw;
		line-height: $baseline;
	}

	@include mq($br-large) {
		//till 1440px
		//font-size: 20px;
		font-size: 1.4vw;
		line-height: $baseline;
	}

	@include mq($br-med-large) {
		//1280px
		//font-size: 18px;
		font-size: 1.4vw;
		line-height: $baseline;
	}

	@include mq($br-medium) {
		//1024px
		//font-size: 16px;
		font-size: 2.1vw;
		line-height: $baseline;
	}

	@include mq($br-med-small) {
		//768px
		font-size: 15px;
		line-height: $baseline--small;
	}

	@include mq($br-small) {
		//520px
		font-size: 13px;
		line-height: $baseline--small;
	}

	@include mq(340px) {
		font-size: 12px;
		line-height: $baseline--small;
	}

}

// Typography
@mixin sans--large() {
	font-family: $sans;
	font-size: 2.8vw;
	line-height: 1.1em;

	@include mq($br-medium) {
		font-size: 4.2vw;
	}

	@include mq($br-med-small) {
		//768px
		font-size: 30px;
		line-height: 1.125em;
		//line-height: $baseline;
	}

	@include mq($br-small) {
		//520px
		font-size: 26px;
		line-height: 1.125em;
		//line-height: $baseline;
	}
	@include mq(340px) {
		font-size: 20px;
		line-height: 1.125em;
	}
}

// Typography
@mixin sans--small() {
	font-family: $sans;
	font-size: 0.90vw;
	line-height: $baseline;
	@include mq($br-xlarge) {
		//till 1800px
		//font-size: 14px;
		font-size: 0.90vw;
		line-height: $baseline;
	}

	@include mq($br-large) {
		//till 1440px
		//font-size: 12px;
		font-size: 0.90vw;
		line-height: $baseline;
	}

	@include mq($br-med-large) {
		//1280px
		font-size: 12px;
		font-size: 0.90vw;
		line-height: $baseline;
	}

	@include mq($br-medium) {
		//1024px
		font-size: 10px;
		line-height: $baseline;
	}

	@include mq($br-med-small) {
		//768px
		font-size: 10px;
		line-height: $baseline--small;
	}

	@include mq($br-small) {
		//520px
		font-size: 9px;
		line-height: $baseline--small;
	}
}
