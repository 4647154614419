// Animation

$fast		  : 0.220s;
$speed		: 0.330s;
$slow     : 0.440s;
$ease			: ease;

.fade-out { animation: fade-out $speed $ease forwards !important; }
.fade-in { animation: fade-in $speed $ease forwards; }

@keyframes fade-out {
    from { opacity: 1; } /* no comma here */
    to { opacity: 0; }
}

@keyframes fade-in {
    from { opacity: 0; } /* no comma here */
    to { opacity: 1; }
}
