

.c-navigation-list-item {
  float: left;
  display: inline-block;

  a {
    display:block;
    height: 100%;
    padding-left: $baseline/5;
    padding-right: $baseline/5;
  }

  &:first-child {
    a {
      padding-left: 0;
    }
  }

  &:last-child {
    a {
    padding-right: 0;
    }
  }

}
